<template>
  <div class="warp">
    <div class="num">{{ dataList.length }} / 好友</div>
    <div class="scroll">
      <div
        class="item"
        v-for="item in dataList"
        :key="item.id"
        @click="goFriendesDetail(item.id)"
      >
        <img :src="item.imgurl" alt="" />
        <div class="user-info">
          <span>姓名</span>
          <span>{{ item.name }}</span>
        </div>
        <div class="user-info">
          <span>性别</span>
          <span>{{ item.sex }}</span>
        </div>
        <div class="user-info">
          <span>公司</span>
          <span>{{ item.company }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant'
import { getMapPos } from '../../api'
let timer
// import func from 'vue-editor-bridge'
export default {
  data () {
    return {
      show: true,
      actions: [{
        name: '拨打电话',
        index: 1
      }, {
        name: '复制电话',
        index: 2
      }],
      dataList: []
    }
  },
  async created () {
    const that = this
    const data = await getMapPos({
      cardnum: this.$cookies.get('cardNum')
    })

    this.dataList = data.data[0].friend

    timer = setInterval(async function () {
      const data = await getMapPos({
        cardnum: this.$cookies.get('cardNum')
      })

      that.dataList = data.data[0].friend
    }, 1000 * 10)
  },
  methods: {
    goFriendesDetail (id) {
      this.$router.push(`/friends-details?uid=${id}`)
    },
    selectAct (data) {
      if (data.index === 2) {
        // window.clipboardData.setData('Text', 13212341234)
        this.$copyText('13212341234').then(res => {
          Toast('复制成功')
        })
      }
      if (data.index === 1) {
        window.location.href = 'tel:13212341234'
      }
    }
  },
  destroyed () {
    clearInterval(timer)
  }
}
</script>

<style lang='less' scoped>
.warp {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  .num {
    margin: 4vw 5vw;
    margin-bottom: 0;
    color: #141e37;
    font-size: 3vw;
  }
  .scroll {
    height: 161vw;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;

    .item {
      width: 55vw;
      height: 74vw;
      background-color: #ffffff;
      box-shadow: 0vw 1vw 3vw 0vw rgba(114, 141, 172, 0.28);
      border-radius: 1vw;
      margin-left: 5vw;
      margin-top: 5vw;
      padding: 4vw 5vw;
      box-sizing: border-box;
      img {
        width: 45vw;
        height: 46vw;
        border-radius: 1vw;
      }
      .user-info {
        display: flex;
        height: 7vw;

        span {
          font-size: 3vw;
          line-height: 7vw;
          color: #141e37;
          &:last-child {
            margin-left: 2vw;
            height: 6vw;
            width: 36vw;
            line-height: 6vw;
            border-bottom: 1px solid rgb(224, 227, 236);
            text-align: center;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
