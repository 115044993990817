<template>
  <div class="warp">
    <div class="inner">
      <div class="ele">
        <img src="../../assets/img/title.png" alt="" class="title" />
        <img src="../../assets/img/peishi1.png" alt="" class="peishi" />
      </div>
      <div class="content">
        <div class="form">
          <div class="item">
            <span>头像</span>
            <div class="avatar-warp">
              <!-- <img src="./img/edit.png" @click="editUser" alt="" class="edit" /> -->

              <div class="avatar">
                <img :src="userInfo.imgurl" alt="" class="avatar" />
              </div>
            </div>
          </div>
          <div class="item">
            <span>姓名</span>
            <input :readonly="edit" type="text" v-model="userInfo.name" />
          </div>
          <div class="item">
            <span>性别</span>
            <van-radio-group
              :disabled="edit"
              v-model="userInfo.sex"
              direction="vertical"
            >
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </div>
          <div class="item">
            <span>公司</span>
            <input :readonly="edit" type="text" v-model="userInfo.company" />
          </div>
          <div class="item">
            <span>技术方向</span>
            <input readonly type="text" v-model="userInfo.fangxiang" />
          </div>
          <div class="item" @click="handleTel">
            <span>联系电话</span>
            <input
              type="text"
              readonly
              v-model="telVal"
            />
            <!-- <img src="./img/eye.png"  class="eys"  @click="eye(1)" alt=""> -->
          </div>
          <div class="item">
            <span>社交方式</span>
            <input
              :readonly="edit"
              v-model="contantVal"
              type="text"
            />
            <!-- <img src="./img/eye.png" class="eys"  @click="eye(2)"  alt=""> -->
          </div>
        </div>
      </div>
      <!-- <div class="sure" @click="updateUser">完成</div> -->
      <!-- <p class="tip">
        *如遇数据未更新时请稍等片刻，或在Badge上利用方向键
切换到「现在同步」进行手动同步。
      </p> -->
      <img class="footer" src="./img/footer.png" alt="" />
    </div>
    <van-action-sheet
      v-model="showTel"
      :actions="actions"
      cancel-text="取消"
      @select="selectAct"
      close-on-click-action
    />
  </div>
</template>

<script>
import { queryFriend } from '../../api'
import { RadioGroup, Radio, ActionSheet, Toast } from 'vant'
export default {
  components: {
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
    'van-action-sheet': ActionSheet
  },
  data () {
    return {
      show: false,
      showAvatar: true,
      showTel: false,
      actions: [
        {
          name: '拨打电话',
          index: 1
        },
        {
          name: '复制电话',
          index: 2
        }
      ],
      edit: true,

      telVal: '',
      contantVal: '',
      userInfo: {},
      avatart: '',
      result: [],
      options: {
        confirmButtonBackgroundColor: '#134ae2',
        outputSize: '0.5',
        autoCropWidth: '150',
        autoCropHeight: '150'
      },
      checkboxList: [
        {
          name: 'CV计算机视觉',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: 'NLP自然语言处理',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: 'Rec推荐',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '推理部署',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '强化学习',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '分布式训练',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '其他',
          checked: false,
          disabled: false,
          color: '#000'
        }
      ]
    }
  },
  watch: {
    'userInfo.phonestatus' () {
      let val = ''

      // eslint-disable-next-line eqeqeq
      if (this.userInfo.phonestatus == 1) {
        val = this.userInfo.phone
      // eslint-disable-next-line eqeqeq
      } else if (this.userInfo.phonestatus == 2) {
        val = this.phoneFilter(this.userInfo.phone)
      } else {
        val = this.userInfo.phone
      }
      this.telVal = val
    },
    'userInfo.friendstatus' () {
      let val = ''

      // eslint-disable-next-line eqeqeq
      if (this.userInfo.friendstatus == 1) {
        val = this.userInfo.shejiao
      // eslint-disable-next-line eqeqeq
      } else if (this.userInfo.friendstatus == 2) {
        val = this.contactFilter(this.userInfo.shejiao)
      } else {
        console.log(1111111)
        val = this.userInfo.shejiao
      }
      this.contantVal = val
    }
  },
  computed: {
    formatRes () {
      return this.result.join(',')
    }
  },
  created () {
    // this.$router.push('/menu')
    queryFriend({ id: this.$route.query.uid }).then((res) => {
      // eslint-disable-next-line eqeqeq
      if (res.code == 200) {
        this.userInfo = res.data
        console.log(res.data.phone)
        this.telVal = res.data.phone
      }
    })
  },
  methods: {
    editUser () {
      this.edit = !this.edit
    },
    phoneFilter (val) {
      const reg = /^(.{3}).*(.{4})$/
      return val.replace(reg, '$1****$2')
    },
    contactFilter (val = '') {
      if (val.length >= 5) {
        return val.substr(0, 2) + '***' + val.substr(val.length - 2, val.length - 1)
      } else {
        return val.substr(0) + '***' + val.substr(val.length - 1)
      }
    },
    handleTel () {
      // eslint-disable-next-line eqeqeq
      if (this.userInfo.phonestatus == 2) return
      this.showTel = true
    },
    selectAct (data) {
      if (data.index === 2) {
        // window.clipboardData.setData('Text', 13212341234)
        this.$copyText(this.userInfo.phone).then(res => {
          Toast('复制成功')
        })
      }
      if (data.index === 1) {
        window.location.href = `tel:${this.userInfo.phone}`
      }
    },
    eye (type) {
      // alert(type)
      if (this.edit) return

      if (type === 1) {
        if (this.userInfo.phonestatus === '1') {
          this.$set(this.userInfo, 'phonestatus', '2')
        } else {
          this.$set(this.userInfo, 'phonestatus', '1')
        }
      }

      if (type === 2) {
        if (this.userInfo.friendstatus === '1') {
          this.$set(this.userInfo, 'friendstatus', '2')
        } else {
          this.$set(this.userInfo, 'friendstatus', '1')
        }
      }
    }
  },

  mounted () {
    // this.$refs.cropper.goAutoCrop()
  }
}
</script>

<style lang='less' scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 5vw;
  .van-radio-group {
    display: flex;
    div {
      margin-right: 5vw;
    }
  }
  .van-checkbox {
    margin: 4vw auto;
  }
  .van-action-sheet__content {
    width: 80%;
    margin: 0 auto;
  }
  .inner {
    // width: 84vw;
    margin: 0 auto;
    .ele {
      display: flex;
      .title {
        width: 45vw;
        height: 24vw;
        margin-left: 11vw;
      }
      .peishi {
        width: 25vw;
        height: 28vw;
        margin-left: 19vw;
        margin-top: 4vw;
      }
    }
    .content {
      width: 84vw;
      margin: 4vw auto 0;
      height: 101vw;
      background-color: #ffffff;
      border-radius: 3vw;
      box-shadow: 0 0 4vw 1vw rgba(0, 0, 0, 0.1);
      .form {
        width: 73vw;
        margin: 0 auto;
        .item {
          height: 12vw;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 1px solid rgba(20, 30, 55, 0.3);
          span {
            width: 21vw;
          }
          &:first-child {
            height: 18.133vw;
            display: flex;
            align-items: center;
          }
          input {
            height: 12vw;
            outline: none;
            border: none;
            padding-left: 2vw;
          }
          .eys {
            position: absolute;
            width: 3vw;
            right: 0;
          }
        }
        .avatar-warp {
          width: 13vw;
          position: absolute;
          right: 0;
          margin-top: 1vw;
          input {
            width: 13vw;
            opacity: 0;
            position: absolute;
            top: 3vw;
            left: 0;
          }
        }
        .edit {
          width: 10vw;
          display: block;
        }
        .avatar {
          width: 13vw;
          height: 13vw;
          display: block;
          margin-top: 2vw;
          position: relative;
          .upbtn {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            margin-top: 0;
            width: 12vw;
          }
        }
      }
    }
  }
  .sure {
    width: 84vw;
    height: 12vw;
    background-color: #3788f7;
    box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
    border-radius: 1vw;
    margin: 4vw auto;
    line-height: 12vw;
    color: #fff;
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
  }
  .tip {
    width: 84vw;
    margin: 0 auto;
    font-size: 3vw;
    color: #b8bbc3;
    line-height: 5vw;
  }
  .footer {
    width: 42vw;
    margin-top: 7vw;
    margin-left: 11vw;
  }
  // .cropper {
  //   width: 80px;
  //   height: 80px;
  //   line-height: 80px;
  //   /* 切记position: relative一点要有 */
  //   position: relative;
  //   border-radius: 80px;
  //   overflow: hidden;
  //   text-align: center;
  // }
}
</style>
