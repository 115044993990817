import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Login'
import Agenda from '../views/Agenda'
import Menu from '../views/Menu'
import MyCard from '../views/MyCard'
import User from '../views/User'
import Map from '../views/Map'
import Friends from '../views/Friends'
import FriendsDetail from '../views/FriendsDetail'
import ClearData from '../views/ClearData'
import CheckIn from '../views/CheckIn'

import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: false
    }
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda,
    meta: {
      auth: true
    }

  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    meta: {
      auth: true
    }

  },
  {
    path: '/my-card',
    name: 'MyCard',
    component: MyCard,
    meta: {
      auth: true
    }

  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      auth: true
    }

  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: {
      auth: true
    }

  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends,
    meta: {
      auth: true
    }

  },
  {
    path: '/friends-details',
    name: 'FriendsDetail',
    component: FriendsDetail,
    meta: {
      auth: true
    }

  },
  {
    path: '/check-in',
    name: 'CheckIn',
    component: CheckIn,
    meta: {
      auth: true
    }

  },
  {
    path: '/clear-data',
    name: 'ClearData',
    component: ClearData

  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const tel = VueCookies.get('tel')
  const auth = to.meta.auth
  if (auth && !tel) {
    router.push('/')
    return
  }

  next()
})

export default router
