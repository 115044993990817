import axios from 'axios'

const request = axios.create({
  baseURL: 'https://i-hudong.com/www/wavesummit/index.php/index',
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
})

request.interceptors.response.use(res => res.data)

export default request
