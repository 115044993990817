<template>
  <div class="agenda">
    <img
      v-show="scrollTop > 300"
      src="./img/icon_gotop.png"
      @click="backtop"
      class="go-top"
      alt=""
    />
    <img src="./img/banner.png" alt="" class="banner" />
    <div class="tab">
      <div :class="agendaShow ? 'active' : ''">
        <p @click="agendaClick(1)">主会议程</p>
      </div>
      <div :class="agendaShow ? '' : 'active'">
        <p @click="agendaClick(2)">分会议程</p>
        <img
          src="./img/arrow.png"
          alt=""
          class="arrow"
          @click="fenhui"
          v-if="agendaShow"
        />
        <img
          src="./img/arrowAct.png"
          alt=""
          class="arrow"
          @click="fenhui"
          v-else
        />
      </div>
      <div class="tabTwoAct" v-show="fenhuiShow">
        <a href="javascript:;">飞桨升级 创新引领</a>
        <a href="javascript:;">交叉前沿 AI共拓</a>
        <a href="javascript:;">产业护航 聚力共振</a>
        <a href="javascript:;">智能核芯 生态共赢</a>
        <a href="javascript:;">开源开放 生态共建</a>
        <a href="javascript:;">产教融合 人才共育</a>
        <a href="javascript:;">科技创新 女性力量</a>
      </div>
    </div>
    <div class="agendaWarp 321" v-show="agendaShow">
      <div class="inner" v-for="(item, index) in agenda" :key="index">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="content">
          <div class="item" v-for="(child, key) in item.data" :key="key * 11">
            <div class="time">
              {{ child.time }}
            </div>
            <div class="right">
              <div class="top" v-html="child.title"></div>
              <div class="center" v-html="child.speaker">
                <!-- {{ child.speaker }} -->
              </div>
              <div class="job" v-html="child.job"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agendaWarp 121212 fenhui" v-show="!agendaShow">
      <div
        class="inner"
        v-for="(item, index) in agendaList"
        :key="index"
        :id="item.index"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="content">
          <div class="item" v-for="(child, key) in item.data" :key="key * 10">
            <div class="time">
              {{ child.time }}
            </div>
            <div class="right">
              <div class="top" v-html="child.title"></div>
              <div class="center" v-html="child.speaker"></div>
              <div class="job" v-html="child.job"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agendaWarp">
      <div class="inner">
        <div class="title">演讲嘉宾<span>SPEAKERS</span></div>
        <div class="content">
          <div class="speaker-warp">
            <div class="speaker" v-for="speak in speakers" :key="speak.imgname">
              <img
                :src="
                  'https://wavesummit.event.com.cn/speakerImg/' +
                  speak.imgname +
                  '.png'
                "
                alt=""
              />
              <p class="name">{{ speak.name }}</p>
              <p class="job" v-html="speak.title">
                百度首席技术官<br />
                深度学习技术及应用国家工程实验室主任
              </p>
            </div>
          </div>
        </div>

        <div class="title" style="margin-top: 5vw">
          活动地点<span>ACTIVITY LOCATION</span>
        </div>
        <div class="content">
          <img src="./img/map.png" alt="" class="map" />
          <!-- <img src="./img/maps.png" class="map" @click="OPENMAP" alt=""> -->

          <baidu-map
            @click="OPENMAP"
            center="金色炉台"
            :zoom="17"
            class="bmap"
            style="display: flex; flex-direction: column"
          >
            <bm-view style="width: 100%; height: 100%; flex: 1"></bm-view>
          </baidu-map>
        </div>
      </div>
    </div>
    <a
      :href="'https://form.event.com.cn/f/tWufL9?x_field_1=' + userInfo.userid"
      class="poster"
      >调查问卷</a
    >
    <img src="./img/footer.png" class="footer" alt="" />
  </div>
</template>
<script>
import { getUserInfo } from '../../api'
export default {
  data () {
    return {
      nearby: {
        center: {
          lng: 116.404,
          lat: 39.915
        },
        radius: 1000
      },
      agendaShow: true,
      fenhuiShow: false,
      scrollTop: 0,
      userInfo: {},
      agendaList: [
        {
          title: '飞桨升级 创新引领',
          index: 'agenda1',
          data: [
            {
              time: '15:45-15:50',
              title: '深度学习框架趋势洞察',
              speaker: '毕然',
              job: '百度杰出架构师<br>飞桨产品负责人'
            },
            {
              time: '15:50-16:05',
              title: '分布式框架：自适应架构和超大模型训练',
              speaker: 'Eva',
              job: '百度主任研发架构师'
            },
            {
              time: '16:05-16:20',
              title: '面向未来：飞桨科学计算的探索之路',
              speaker: '轻舟',
              job: '百度高级技术产品经理'
            },
            {
              time: '16:20-16:35',
              title: '赋能硬件生态，飞桨框架统一硬件接入方案解析',
              speaker: '蓝翔',
              job: '百度资深研发工程师'
            },
            {
              time: '16:35-16:45',
              title: '飞桨企业版端边云多场景部署能力全新升级',
              speaker: '新酱',
              job: '百度资深研发工程师'
            },
            {
              time: '16:45-17:00',
              title: '飞桨EasyDL桌面版全新发布：实现本地一站式模型训练与部署',
              speaker: '晚兮',
              job: '百度资深研发工程师'
            },
            {
              time: '17:00-17:15',
              title: '飞桨产业级模型库全新升级',
              speaker: 'CVRunner',
              job: '百度资深研发工程师'
            },
            {
              time: '17:15-17:30',
              title: '更快更易用的文本任务全流程开发',
              speaker: '泽裕',
              job: '百度资深研发工程师'
            },
            {
              time: '17:30-17:40',
              title: 'MetaGym：强化学习团队首次开源元学习环境',
              speaker: '青云',
              job: '百度资深研发工程师<br>百度强化学习方向负责人'
            },
            {
              time: '17:40-17:50',
              title: '解语：中文文本知识关联框架',
              speaker: 'Severus',
              job: '百度高级研发工程师'
            },
            {
              time: '17:50-18:05',
              title: 'PaddleSleeve：AI模型安全与隐私工具',
              speaker: '沉浮',
              job: '百度主任研发架构师'
            }
          ]
        },
        {
          title: '交叉前沿 AI共拓',
          index: 'agenda2',
          data: [
            {
              time: '15:45-15:55',
              title: '深度学习X科学前沿趋势洞察',
              speaker: '窦德景',
              job: '百度研究院大数据实验室和商业智能实验室主任<br>美国俄勒冈大学计算机系教授'
            },
            {
              time: '15:55-16:10',
              title: 'AI+药物设计：AI制药与产业前沿',
              speaker: '尹航',
              job: '清华大学药学院副院长'
            },
            {
              time: '16:10-16:25',
              title: 'AI+CFD：面向空天动力的科学机器学习新方法与新范式',
              speaker: '秦飞',
              job: '西北工业大学航天学院副院长'
            },
            {
              time: '16:25-16:40',
              title: 'AI+超算：AI赋能基于网格离散的科学与工程计算',
              speaker: '刘杰',
              job: '国防科技大学研究员'
            },
            {
              time: '16:40-16:55',
              title: 'AI+医疗：AI与增强现实在手术导航中的应用及临床转化',
              speaker: '杨健',
              job: '北京理工大学光电学院教授'
            },
            {
              time: '16:55-17:10',
              title: 'AI+遥感：释放每个像元价值',
              speaker: '陈正超',
              job: '中国科学院空天信息创新研究院教授'
            },
            {
              time: '17:10-17:25',
              title: 'AI+区块链：链桨-基于区块链的可信分布式AI',
              speaker: '荆博',
              job: '百度区块链技术负责人&百度区块链副总经理'
            },
            {
              time: '17:25-17:40',
              title: 'AI+生物计算：PaddleHelix在药物研发的创新',
              speaker: '方晓敏',
              job: '百度资深研发工程师'
            },
            {
              time: '17:40-17:55',
              title: 'AI+量子计算：量子计算与AI的融合创新',
              speaker: '王鑫',
              job: '百度研究院量子计算研究所资深研究员'
            },
            {
              time: '17:55-18:05',
              title: 'AI+元宇宙：元宇宙与数字人',
              speaker: '李士岩',
              job: '百度智能云AI人机交互实验室负责人'
            }
          ]
        },
        {
          title: '产业护航 聚力共振',
          index: 'agenda4',
          data: [
            {
              time: '15:45-16:00',
              title: 'AI赋能产业变革，加速全行业智能化升级',
              speaker: '钟俊浩',
              job: '上海市人工智能行业协会/上海市人工智能标准化技术委员会秘书长'
            },
            {
              time: '16:00-16:15',
              title: '文心大模型ERNIE助力企业构建智能化应用',
              speaker: '王硕寰',
              job: '百度自然语言处理部资深研发工程师'
            },
            {
              time: '16:15-16:30',
              title: '荣耀借力飞桨生态全面构建NLP中台',
              speaker: '汤泳',
              job: '荣耀人工智能模式识别实验室负责人'
            },
            {
              time: '16:30-16:45',
              title: '百度网盘基于人工智能的业务升级',
              speaker: '施鹏',
              job: '百度网盘主任研发架构师'
            },
            {
              time: '16:45-16:55',
              title: '飞桨优选级技术伙伴授牌仪式',
              speaker: '',
              job: ''
            },
            {
              time: '16:55-17:10',
              title: '产学研携手共助钢铁行业智能化升级',
              speaker: '余炯',
              job: '华院计算智能制造项目总监'
            },
            {
              time: '17:10-17:25',
              title: '传统企业偶遇EasyDL快速实现全流水线智能质检',
              speaker: '张强',
              job: '哲元科技联合创始人'
            },
            {
              time: '17:25-17:40',
              title: '飞桨助力宁波实现“智能垃圾分类”推进“塑料减排”  ',
              speaker: '杨洋',
              job: '宁波搭把手生态数字科技总工程师'
            },
            {
              time: '17:40-17:55',
              title: '飞桨产业实践范例库助力企业跨越AI落地鸿沟',
              speaker: '吕雪莹',
              job: '百度飞桨高级产品经理'
            }
          ]
        },
        {
          title: '智能核芯 生态共赢',
          index: 'agenda5',
          data: [
            {
              time: '15:45-16:00',
              title: 'AI芯片行业现状、趋势及十四五展望',
              speaker: '徐秀法',
              job: '中国半导体行业协会集成电路设计分会副秘书长'
            },
            {
              time: '16:00-16:15',
              title: '昆仑芯让计算更智能',
              speaker: '韩金宸',
              job: '百度资深研发工程师'
            },
            {
              time: '16:15-16:30',
              title: '从TCO 评判AI加速产品',
              speaker: '刘建航',
              job: 'Intel首席工程师'
            },
            {
              time: '16:30-16:45',
              title: 'ARM携手飞桨，赋能端侧AI数字化升级',
              speaker: '杨瑞',
              job: 'ARM高级技术市场经理'
            },
            {
              time: '16:45-17:00',
              title: '华为昇腾联合飞桨打造高性能计算方案',
              speaker: '薛亮',
              job: '华为昇腾解决方案架构师'
            },
            {
              time: '17:00-17:15',
              title: '基于安培GPU稀疏特征的飞桨训练、推理与部署',
              speaker: '王诗杰',
              job: 'NVIDIA GPU计算专家团队深度学习专家'
            },
            {
              time: '17:15-17:30',
              title: '国产CPU与框架的碰撞：飞腾产业智能解决方案全析',
              speaker: '谷潇聪',
              job: '飞腾行业解决方案资深架构师'
            },
            {
              time: '17:30-17:45',
              title: '飞桨在IPU上实现训练和推理全面支持',
              speaker: '金琛',
              job: 'Graphcore中国工程总负责人<br>AI算法科学家'
            },
            {
              time: '17:45-18:00',
              title: '飞桨国产化一体机，打通AI落地“最后一公里”',
              speaker: '张学聪',
              job: '百度AI产品业务部产品经理'
            }
          ]
        },
        {
          title: '开源开放 生态共建',
          index: 'agenda3',
          data: [
            {
              time: '15:45-16:00',
              title: '群智范式下的开源创新',
              speaker: '余跃',
              job: '国防科技大学副研究员<br>OpenI启智社区运营中心主任'
            },
            {
              time: '16:00-16:15',
              title: '万语千言 共建中文开源数据集',
              speaker: '黄民烈',
              job: '清华大学长聘副教授<br>中文信息学会自然语言生成与智能写作专委会副主任'
            },
            // {
            //   time: "16:10-16:25",
            //   title: "人工智能时代的开源开放与创新",
            //   speaker: "马越",
            //   job: "开源中国CEO<br>恒拓开源创始人、董事长",
            // },
            {
              time: '16:15-16:30',
              title: '白玉兰开源：从论文到产品的可复现人工智能',
              speaker: '金耀辉',
              job: '上海白玉兰开源研究院执行院长<br>上海交通大学电信学院长聘教授<br>人工智能研究院总工程师'
            },
            {
              time: '16:30-16:45',
              title: 'RocketQA：端到端问答模型的技术发展与社区生态',
              speaker: '刘璟',
              job: '百度自然语言处理部 主任研发架构师'
            },
            {
              time: '16:45-17:00',
              title: 'Finetuner：一站式微调基于Jina的神经搜索系统',
              speaker: '王楠',
              job: 'Jina AI联合创始人兼CTO'
            },
            {
              time: '17:00-17:15',
              title:
                'MLFlow：开放式机器学习平台New Feature：autologging与PaddlePaddle的实现',
              speaker: '张进',
              job: 'MLFlow及Databricks实验管理服务技术负责人'
            },
            {
              time: '17:15-17:30',
              title: '使用PaddlePaddle和OpenVINO轻松进行AI推理',
              speaker: '武卓',
              job: 'OpenVINO布道师'
            },
            {
              time: '17:30-17:45',
              title: 'TensorIR：TVM新一代面向张量计算单元的可调度IR',
              speaker: '冯思远',
              job: 'Apache TVM Committer'
            },
            {
              time: '17:45-18:00',
              title:
                'PaddleSpatial：深度学习与时空大数据结合&nbsp;助力智慧城市发展',
              speaker: '周景博',
              job: '百度研究院资深研究员<br>PaddleSpatial 技术负责人'
            }
          ]
        },
        {
          title: '产教融合 人才共育',
          index: 'agenda7',
          data: [
            {
              time: '14:00-14:05',
              title: '教指委致辞',
              speaker: '蒋宗礼',
              job: '国家级教学名师<br>教育部高等学校计算机类专业教学指导委员会副主任委员，教授'
            },
            {
              time: '14:05-14:40',
              title: '走内涵发展之路  提高人才培养质量和水平',
              speaker: '蒋宗礼',
              job: '国家级教学名师<br>教育部高等学校计算机类专业教学指导委员会副主任委员，教授'
            },
            {
              time: '14:40-15:00',
              title: '上海交通大学人工智能专业建设探索与实践',
              speaker: '韩韬',
              job: '上海交通大学电子信息与电气工程学院副院长'
            },
            {
              time: '15:00-15:20',
              title: '新工科背景下人工智能创新人才培养思考',
              speaker: '张维刚',
              job: '哈尔滨工业大学（威海）计算机科学学院 副院长'
            },
            {
              time: '15:20-15:30',
              title: '中国高校计算机大赛人工智能创意赛分享',
              speaker: '何钦铭',
              job: '浙江大学求是特聘学者<br>国家万人计划教学名师<br>教育部大学计算机课程教学指导委员会副主任委员'
            },
            {
              time: '15:30-15:40',
              title: '人工智能助力全国大学生智能汽车竞赛',
              speaker: '卓晴',
              job: '全国大学智能汽车竞赛组委会秘书长<br>清华大学自动化系副教授'
            },
            {
              time: '15:40-15:50',
              title: 'AI+艺术与文博的赛与学',
              speaker: '王旭东',
              job: '清华大学中意设计创新基地办公室主任<br>清华大学吴冠中艺术与科学创新基金管委会秘书长'
            },
            {
              time: '15:50-16:10',
              title: '基于“学-练-研-评”多维驱动的人工智能核心课程群建设',
              speaker: '邱锡鹏',
              job: '复旦大学计算机科学技术学院 教授'
            },
            {
              time: '16:10-16:30',
              title: '浅探实践驱动下的研究生创新能力培养',
              speaker: '杨杨',
              job: '南京理工大学计算机科学与工程学院 教授'
            },
            {
              time: '16:30-16:40',
              title: '校企合作共推高校人工智能师资培训工作',
              speaker: '邓月明',
              job: '湖南师范大学信息科学与工程学院实验中心副主任'
            },
            {
              time: '16:40-16:50',
              title: '飞桨师资培训优秀讲师颁奖',
              speaker:
                '颁奖嘉宾：<br>蒋宗礼<br>国家级教学名师<br>教育部高等学校计算机类专业教学指导委员会副主任委员，教授',
              job: '<br>刘倩<br>百度AI技术生态部总经理'
            }
          ]
        },
        {
          title: '科技创新 女性力量',
          index: 'agenda6',
          data: [
            {
              time: '15:45-15:50',
              title: '开场致辞',
              speaker: '迟楠',
              job: '复旦大学信息科学与工程学院院长，博士生导师'
            },
            {
              time: '15:50-16:05',
              title: '连接世界与你的那盏灯：可见光通信助力万物互联',
              speaker: '迟楠',
              job: '复旦大学信息科学与工程学院院长，博士生导师'
            },
            {
              time: '16:05-16:20',
              title: '逐梦太空，情献北斗：北斗导航技术与产业应用',
              speaker: '王淑芳',
              job: '中国交通通信信息中心导航中心副主任<br>正高级工程师'
            },
            {
              time: '16:20-16:35',
              title: '面由心生，由脸观心：基于AI的面部微表情分析技术解读',
              speaker: '贲晛烨',
              job: '山东大学教授<br>山东大学齐鲁青年学者'
            },
            {
              time: '16:35-16:50',
              title: '5G通达，AI赋能：AI在网络规划中的应用实践',
              speaker: '董江波',
              job: '中国移动通信集团设计院有限公司教授级高工'
            },
            {
              time: '16:50-17:05',
              title: '从小样本学习出发，奔向星辰大海',
              speaker: '王雅晴',
              job: '百度研究院资深研究员<br>飞桨高级开发者技术专家（PPDE） '
            },
            {
              time: '17:05-17:20',
              title: '生命科学探索：深度学习赋能新药研发',
              speaker: '张青青',
              job: 'NVIDIA医疗行业资深顾问'
            },
            {
              time: '17:20-18:00',
              title: '圆桌对话：智能时代背后的科技女性力量',
              speaker:
                '徐鸣<br>百度飞桨开发者生态运营负责人<br><br>王淑芳<br>中国交通通信信息中心导航中心副主任<br>正高级工程师<br><br>贲晛烨<br>山东大学教授<br>山东大学齐鲁青年学者<br><br>董江波<br>中国移动通信集团设计院有限公司教授级高工<br><br>王雅晴<br>百度研究院资深研究员<br>飞桨高级开发者技术专家（PPDE）',
              job: ''
            }
          ]
        }
      ],
      agenda: [
        {
          title: '主题演讲',
          data: [
            {
              time: '13:00-13:10',
              title: '开场致辞',
              speaker: '王海峰',
              job: '百度首席技术官<br>深度学习技术及应用国家工程实验室主任'
            },
            {
              time: '13:10-13:20',
              title: '主题演讲',
              speaker: '吴甜',
              job: '百度集团副总裁<br>深度学习技术及应用国家工程实验室副主任'
            },
            {
              time: '13:20-13:45',
              title: '飞桨最新发布与升级',
              speaker: '马艳军 <br>百度深度学习技术平台部高级总监',
              job: '<br>忻舟 <br> 百度AI产品研发部总监'
            },
            // {
            //   time: '13:20-13:25',
            //   title: '发布仪式',
            //   speaker: '',
            //   job: ''
            // },
            // {
            //   time: '13:25-13:35',
            //   title: '飞桨主题报告',
            //   speaker: '吴甜',
            //   job: '百度集团副总裁<br>深度学习技术及应用国家工程实验室副主任'
            // },
            // {
            //   time: '13:35-14:00',
            //   title: '飞桨最新发布与升级',
            //   speaker: '马艳军 <br>百度深度学习技术平台部高级总监',
            //   job: '<br>忻舟 <br> 百度AI产品研发部总监'
            // },
            {
              time: '13:45-13:55',
              title: 'AI助力新型电力系统建设，服务“双碳”战略目标',
              speaker: '肖飞',
              job: '国家电网上海电力公司调度中心副主任'
            },
            {
              time: '13:55-14:05',
              title: '面向科学发现的智能计算环境',
              speaker: '王彦棡',
              job: '中国科学院计算机网络信息中心人工智能技术与应用发展部主任'
            },
            {
              time: '14:05-14:15',
              title: '飞桨图计算助力超大规模推荐系统创新迭代',
              speaker: '段石石',
              job: '网易云音乐机器学习平台与框架负责人 '
            },
            {
              time: '14:15-14:20',
              title: '产业应用创新奖颁奖仪式',
              speaker: '吴甜<br>百度集团副总裁<br>深度学习技术及应用国家工程实验室副主任',
              job: '<br>倪前龙<br>上海市科协副主席'
            },
            {
              time: '14:20-14:50',
              title: '圆桌对话：产业发展新阶段，AI人才培养的机遇与挑战',
              speaker:
                '窦德景<br>百度研究院大数据实验室和商业智能实验室主任<br>美国俄勒冈大学计算机系教授<br><br>王晓阳<br>复旦大学特聘教授<br>原复旦大学计算机科学技术学院、软件学院院长<br>',
              job: '<br>何钦铭<br>浙江大学求是特聘学者<br>国家万人计划教学名师<br>教育部大学计算机课程教学指导委员会副主任委员<br><br>周爱民<br>华东师范大学计算机科学与技术学院副院长<br>华东师范大学上海智能教育研究院副院长<br>'
            },
            {
              time: '14:50-15:00',
              title: '校企合作，协同育人，探索“菁英班”创新人才培养新模式',
              speaker: '罗新民',
              job: '西安交通大学电信学部副主任<br>陕西省高等学校教学名师<br>国家优秀教学成果奖获得者'
            },
            {
              time: '15:00-15:05',
              title: 'ONNX：更开放的神经网络开发框架互通与社区生态',
              speaker: 'Sheng Zha',
              job: 'ONNX Steering Committer<br>Apache MXNet PMC'
            },
            {
              time: '15:05-15:10',
              title: '基于 Kubernetes 的机器学习工具库遇上 PaddlePaddle',
              speaker: 'Yuan Tang ',
              job: 'Kubeflow Co-chair<br>Akuity Founding Engineer'
            },
            {
              time: '15:10-15:20',
              title: '在开源社区的个人成长之路',
              speaker: '曹志浩',
              job: '飞桨开发者技术专家（PPDE）<br>AgentMaker作者'
            },
            {
              time: '15:20-15:30',
              title: '开源遇上公益 — 飞桨与机器人的奇妙之旅',
              speaker: '高鸿志',
              job: '飞桨开发者技术专家（PPDE）'
            },
            {
              time: '15:30-15:40',
              title: '开源开发者代表颁奖仪式',
              speaker: '',
              job: ''
            }
          ]
        }
      ],
      speakers: [
        {
          name: '王海峰',
          title: '百度首席技术官',
          imgname: 'wanghaifeng'
        },
        {
          name: '吴甜',
          title: '百度集团副总裁',
          imgname: 'wutian'
        },
        {
          name: '倪前龙',
          title: '上海市科协副主席',
          imgname: 'niqianlong'
        },
        {
          name: '马艳军',
          title: '百度深度学习技术平台部高级总监',
          imgname: 'mayanjun'
        },
        {
          name: '忻舟',
          title: '百度AI产品研发部总监',
          imgname: 'xinzhou'
        },
        {
          name: '肖飞',
          title: '国家电网上海电力公司调度中心副主任',
          imgname: 'xiaofei'
        },
        {
          name: '王彦棡',
          title: '中国科学院计算机网络 信息中心人工智能技术 与应用发展部主任',
          imgname: 'wangyangang'
        },
        {
          name: '段石石',
          title: '网易云音乐机器学习平台与架构负责人',
          imgname: 'duanshishi'
        },
        {
          name: '窦德景',
          title: '百度研究院大数据实验室和商业智能实验室主任',
          imgname: 'doudejing'
        },
        {
          name: '王晓阳',
          title: '复旦大学特聘教授',
          imgname: 'wangxiaoyang'
        },
        // {
        //   name: '王国仁',
        //   title: '北京理工大学计算机学院院长',
        //   imgname: 'wangguoren'
        // },
        {
          name: '何钦铭',
          title: '浙江大学计算机解学与技术学院教授浙江大学求是特聘学者',
          imgname: 'heqinming'
        },
        {
          name: '周爱民',
          title: '华东师范大学计算机科学与技术学院副院长<br>华东师范大学上海智能教育研究院副院长',
          imgname: 'zhouaimin'
        },
        {
          name: '罗新民',
          title: '西南交通大学电信学部副主任',
          imgname: 'luoxinmin'
        },
        {
          name: 'Sheng zha',
          title: 'ONNX Steering Committer',
          imgname: 'shengzha'
        },
        {
          name: 'Yuan Tang',
          title: 'Kubeflow Co-chair',
          imgname: 'tangyuan'
        },
        {
          name: '曹志浩',
          title: '飞桨开发者技术专家',
          imgname: 'caozhihao'
        },
        {
          name: '高鸿志',
          title: '飞桨开发者技术专家',
          imgname: 'gaohongzhi'
        }
      ]
    }
  },
  async created () {
    const tel = this.$cookies.get('tel')
    const data = await getUserInfo({ phone: tel })
    this.userInfo = data.data
  },
  mounted () {
    const actTit = document.querySelectorAll('.tabTwoAct a')
    const that = this

    for (let i = 0; i < actTit.length; i++) {
      const cur = actTit[i]
      cur.addEventListener('click', () => {
        // eslint-disable-next-line no-unused-expressions

        document.querySelectorAll('.fenhui .title')[i].scrollIntoView()
        that.fenhuiShow = false
      })
    }

    window.addEventListener('scroll', this.showbtn, true)
  },
  methods: {
    showbtn () {
      const that = this
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      that.scrollTop = scrollTop
    },
    backtop () {
      var timer = setInterval(function () {
        const osTop =
          document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    },
    OPENMAP () {
      location.href =
        'https://map.baidu.com/poi/%E9%87%91%E8%89%B2%E7%82%89%E5%8F%B0%C2%B7%E4%B8%AD%E5%9B%BD%E5%AE%9D%E6%AD%A6%E9%92%A2%E9%93%81%E4%BC%9A%E5%8D%9A%E4%B8%AD%E5%BF%83/@13524597.785,3657470.15,19z?uid=c62af3f58afa164a7069d358&info_merge=1&isBizPoi=false&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&newfrom=zhuzhan_webmap&querytype=detailConInfo&da_src=shareurl'
    },
    agendaClick (e) {
      if (e === 1) {
        this.agendaShow = true
      } else {
        this.agendaShow = false
      }
    },
    fenhui () {
      if (this.agendaShow) return
      if (this.fenhuiShow) {
        this.fenhuiShow = false
      } else {
        this.fenhuiShow = true
      }
    }
  }
}
</script>
<style lang='less' scoped>
.agenda {
  width: 100%;
  background: url(./img/bg.jpg) no-repeat top center;
  background-size: cover;
  padding-bottom: 4vw;
}
.banner {
  width: 100%;
}
.tab {
  width: 100%;
  height: 16vw;
  background: #fff;
  display: flex;
  position: relative;
}
.tab div {
  width: 50%;
  text-align: center;
  height: 16vw;
  line-height: 16vw;
  color: #728dac;
  font-weight: bold;
  font-size: 4vw;
  position: relative;
}
.arrow {
  display: block;
  width: 2.667vw;
  position: absolute;
  top: 5vw;
  right: 17%;
  padding: 2.667vw;
}
.tabTwoAct {
  /* display: block !important; */
  width: 40% !important;
  height: auto !important;
  position: absolute !important;
  top: 18vw;
  right: 5%;
  background: white;
  z-index: 99;
  box-shadow: 0vw 0.4vw 1.333vw #728dac;
  padding: 1.333vw 0;
}
.tabTwoAct a {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 2.933vw;
  line-height: 8vw;
  text-align: center;
  color: #728dac;
}
.tab div:first-child::after {
  content: "";
  width: 1px;
  height: 4vw;
  background: #728dac;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tab .active {
  color: #3788f7;
}
.inner {
  width: 87vw;
  margin: 5vw auto 0;
}
.title {
  height: 18vw;
  // line-height: 18vw;
  text-align: center;
  justify-content: center;
  background: #3788f7;
  border-top-left-radius: 4vw;
  border-top-right-radius: 4vw;
  font-size: 4vw;
  color: #fff;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 3vw;
  }
}
.title::before {
  position: absolute;
  content: "";
  width: 4vw;
  height: 6vw;
  background: url(./img/left.png) no-repeat center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  left: 20vw;
}
.title::after {
  position: absolute;
  content: "";
  width: 4vw;
  height: 6vw;
  background: url(./img/right.png) no-repeat center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  right: 20vw;
}
.content {
  background: #fff;
  box-sizing: border-box;
  padding: 10vw 6vw;
}
.content .item {
  display: flex;
  align-items: flex-start;
  font-size: 3vw;
  margin-bottom: 5vw;
}
.content .item .time {
  display: block;
  margin-right: 4vw;
  color: #728dac;
  width: 20vw;
}
.map {
  display: block;
  width: 100%;
}
.bmap {
  width: 72.267vw;
  height: 50.933vw;
}
.right {
  width: 58vw;
}
.right .top {
  color: #3788f7;
  font-weight: bold;
}
.right .center {
  margin-top: 2vw;
  color: #728dac;
}
.right .job {
  color: #728dac;
}
.speaker-warp {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 4vw;
  grid-column-gap: 4vw;
  .speaker {
    img {
      width: 21.6vw;
    }
    .name {
      font-size: 2.667vw;
      color: #3788f7;
    }
    .job {
      font-size: 2.533vw;
      color: #728dac;
    }
  }
}
.footer {
  width: 42.133vw;
  display: block;
  margin-left: 7.2vw;
  margin: 7vw 7.2vw 0;
}
.go-top {
  width: 8vw;
  position: fixed;
  bottom: 10vw;
  right: 6px;
}
.poster {
  width: 64vw;
  height: 12vw;
  background-color: #3788f7;
  box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
  border-radius: 1vw;
  margin: 3vw auto;
  text-align: center;
  line-height: 12vw;
  font-size: 5vw;
  font-weight: bold;
  color: #ffffff;
  line-height: 12vw;
  text-align: center;
  display: block;
  margin: 4vw auto;
}
</style>
