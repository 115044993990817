<template>
  <div class="warp">
    <div class="inner">
      <div class="ele">
        <img src="../../assets/img/title.png" alt="" class="title" />
        <img src="../../assets/img/peishi1.png" alt="" class="peishi" />
      </div>
      <div class="content">
        <div class="form">
          <div class="item">
            <span>上传头像</span>
            <div class="avatar-warp">
              <div class="ava-modal" v-if="edit"></div>
              <div class="avatar">
                <!-- <input type="file" name="img" @change="chnage" /> -->
                <h5-cropper
                  ref="cropper"
                  :option="options"
                  @getbase64="getbase64Data"
                  @getblob="getBlob"
                  @get-file="getFile"
                ></h5-cropper>
                <img
                  v-if="!avatart"
                  src="./img/avatar.png"
                  alt=""
                  class="avatar"
                />
                <img v-else :src="avatart" alt="" class="avatar" />
              </div>
              <!-- <img src="./img/edit.png" @click="editUser" alt="" class="edit" /> -->
            </div>
          </div>
          <div class="item">
            <span>姓名</span>
            <input :readonly="edit" type="text" v-model="userInfo.name" />
          </div>
          <div class="item">
            <span>性别</span>
            <van-radio-group
              :disabled="edit"
              v-model="userInfo.sex"
              direction="vertical"
            >
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </div>
          <div class="item">
            <span>公司</span>
            <input :readonly="edit" type="text" v-model="userInfo.company" />
          </div>
          <div class="item">
            <span>技术方向</span>
            <input @click="showFx" readonly type="text" v-model="formatRes" />
          </div>
          <div class="item">
            <span>联系电话</span>
            <input type="text" readonly v-model="telVal" />
            <img
              src="./img/show.png"
              v-if="userInfo.phonestatus == 1"
              class="eys"
              @click="eye(1)"
              alt=""
            />
            <img
              src="./img/hide.png"
              v-else
              class="eys"
              @click="eye(1)"
              alt=""
            />
          </div>
          <div class="item">
            <span>社交方式</span>
            <input
              :readonly="edit"
              @blur="contantBlur"
              @focus="contantFocus"
              v-model="contantValue"
              type="text"
            />
            <!-- <div class="edit-inp" @input="handleChangeSj" :contenteditable="!edit">{{userInfo.shejiao | contactFilter}}</div> -->
            <!-- <div class="edit-inp" :contenteditable="!edit">{{userInfo.shejiao | contactFilter}}</div> -->
            <img
              src="./img/show.png"
              v-if="userInfo.friendstatus == 1"
              class="eys"
              @click="eye(2)"
              alt=""
            />
            <img
              src="./img/hide.png"
              v-else
              class="eys"
              @click="eye(2)"
              alt=""
            />
          </div>
          <div style="display: flex; justify-content: flex-end;margin-top:2vw">
            <img src="./img/edit.png" @click="editUser" alt="" class="edit" />
          </div>

          <!-- <div class="item edit-user" @click="editUser">编辑</div> -->
        </div>
      </div>
      <div class="sure" @click="updateUser">完成</div>
      <p class="tip">
        *如遇数据未更新时请稍等片刻，或在Badge上利用方向键
        切换到「现在同步」进行手动同步。
      </p>
      <img class="footer" src="./img/footer.png" alt="" />
    </div>
    <van-action-sheet
      v-model="show"
      cancel-text="确定"
      :close-on-click-action="true"
      title="请选择"
    >
      <van-checkbox-group v-model="result">
        <van-checkbox
          v-for="item in checkboxList"
          :key="item.name"
          :name="item.name"
          shape="square"
          >{{ item.name }}</van-checkbox
        >
      </van-checkbox-group>
    </van-action-sheet>
  </div>
</template>

<script>
import H5Cropper from 'vue-cropper-h5'
import {
  getUserInfo,
  uploadAvatar,
  updateUsers,
  detectionWord
} from '../../api'
import {
  ActionSheet,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Toast
} from 'vant'
export default {
  components: {
    'van-action-sheet': ActionSheet,
    'van-checkbox-group': CheckboxGroup,
    'van-checkbox': Checkbox,
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
    H5Cropper
  },

  data () {
    return {
      show: false,
      showAvatar: true,
      edit: true,
      userInfo: {},
      avatart: '',
      telVal: '',
      contantVal: '',
      result: [],
      options: {
        confirmButtonBackgroundColor: '#134ae2',
        outputSize: '0.5',
        autoCropWidth: '3000',
        autoCropHeight: '300'
      },
      checkboxList: [
        {
          name: 'CV计算机视觉',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: 'NLP自然语言处理',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: 'Rec推荐',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '推理部署',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '强化学习',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '分布式训练',
          checked: false,
          disabled: false,
          color: '#000'
        },
        {
          name: '其他',
          checked: false,
          disabled: false,
          color: '#000'
        }
      ]
    }
  },
  computed: {
    formatRes () {
      return this.result.join(',')
    },
    contantValue: {
      // eslint-disable-next-line eqeqeq
      get () {
        // eslint-disable-next-line eqeqeq
        if (this.userInfo.friendstatus == 2 && this.userInfo.shejiao) {
          const val = this.userInfo.shejiao
          const reg = /^(.{2}).*(.{2})$/
          return val.replace(reg, '$1****$2')
        } else {
          return this.userInfo.shejiao
        }

        // return val.substr(0, 2) + '***' + val.substr(val.length - 2, val.length - 1)
      },
      set (newValue) {
        this.$set(this.userInfo, 'shejiao', newValue)
      }
    }
  },
  watch: {
    'userInfo.phonestatus' () {
      let val = ''

      // eslint-disable-next-line eqeqeq
      if (this.userInfo.phonestatus == 1) {
        val = this.userInfo.phone
        // eslint-disable-next-line eqeqeq
      } else if (this.userInfo.phonestatus == 2) {
        val = this.phoneFilter(this.userInfo.phone)
      } else {
        val = this.userInfo.phone
      }
      this.telVal = val
    },
    'userInfo.friendstatus' () {
      let val = ''

      // eslint-disable-next-line eqeqeq
      if (this.userInfo.friendstatus == 1) {
        val = this.userInfo.shejiao
        // eslint-disable-next-line eqeqeq
      } else if (this.userInfo.friendstatus == 2 && this.userInfo.shejiao) {
        val = this.contactFilter(this.userInfo.shejiao)
      } else {
        val = this.userInfo.shejiao
      }
      this.contantVal = val
    }
  },
  created () {
    const tel = this.$cookies.get('tel')
    if (tel) {
      // this.$router.push('/menu')
      getUserInfo({ phone: tel }).then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.code == 200) {
          this.userInfo = res.data
          this.result = res.data.fangxiang.split(',')
          this.avatart = res.data.imgurl
          this.telVal = res.data.phone
        }
      })
    }
  },
  methods: {
    contantFocus () {
      if (this.edit) return
      this.$set(this.userInfo, 'friendstatus', 1)
    },
    contantBlur () {
      if (this.edit) return
      const tel = this.$cookies.get('tel')
      getUserInfo({ phone: tel }).then((res) => {
        this.$set(this.userInfo, 'friendstatus', res.data.friendstatus)
      })
    },
    editUser () {
      this.edit = !this.edit
    },
    phoneFilter (val) {
      const reg = /^(.{3}).*(.{4})$/
      return val.replace(reg, '$1****$2')
    },
    contactFilter (val = '') {
      if (val.length >= 5) {
        return (
          val.substr(0, 2) + '***' + val.substr(val.length - 2, val.length - 1)
        )
      } else {
        return val.substr(0) + '***' + val.substr(val.length - 1)
      }
    },
    getbase64Data (data) {
      this.avatart = data
      uploadAvatar({ imgurl: data }).then((res) => {
        this.avatart = res.data
        if (res.code === '400') {
          Toast(res.msg)
        }
      })
    },
    getBlob (blob) {
    },
    getFile (file) {
    },
    chnage ({ target }) {
      this.$refs.cropper.loadFile(target.files[0])
      target.value = ''
    },
    showFx () {
      if (this.edit) return
      this.show = true
    },
    eye (type) {
      // alert(type)
      if (this.edit) return

      if (type === 1) {
        if (this.userInfo.phonestatus === '1') {
          this.$set(this.userInfo, 'phonestatus', '2')
        } else {
          this.$set(this.userInfo, 'phonestatus', '1')
        }
      }

      if (type === 2) {
        if (this.userInfo.friendstatus === '1') {
          this.$set(this.userInfo, 'friendstatus', '2')
        } else {
          this.$set(this.userInfo, 'friendstatus', '1')
        }
      }
    },
    async updateUser () {
      if (this.edit) return
      // 自定义加载图标
      Toast.loading({
        message: '信息更新中...',
        forbidClick: true,
        overlay: true,
        loadingType: 'spinner'
      }, 0)
      const result = await detectionWord({
        phone: this.userInfo.phone,
        word: this.userInfo.name
      })
      // eslint-disable-next-line eqeqeq
      if (result.code == 400) {
        Toast(result.msg)
        return
      }

      const data = await updateUsers({
        ...this.userInfo,
        fangxiang: this.formatRes,
        imgurl: this.avatart
      })
      Toast.clear()
      // eslint-disable-next-line eqeqeq
      if (data.code == 200) {
        Toast('信息更新成功')
      } else {
        Toast(data.msg)
      }
      this.edit = !this.edit
    }
  },

  mounted () {
    // this.$refs.cropper.goAutoCrop()
  }
}
</script>

<style lang='less' scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 5vw;
  .van-radio-group {
    display: flex;
    div {
      margin-right: 5vw;
    }
  }
  .van-checkbox {
    margin: 4vw auto;
  }
  .van-action-sheet__content {
    width: 80%;
    margin: 0 auto;
  }
  .inner {
    // width: 84vw;
    margin: 0 auto;
    .ele {
      display: flex;
      .title {
        width: 45vw;
        height: 24vw;
        margin-left: 11vw;
      }
      .peishi {
        width: 25vw;
        height: 28vw;
        margin-left: 19vw;
        margin-top: 4vw;
      }
    }
    .content {
      width: 84vw;
      margin: 4vw auto 0;
      padding-bottom: 4vw;
      // height: 101vw;
      background-color: #ffffff;
      border-radius: 3vw;
      box-shadow: 0 0 4vw 1vw rgba(0, 0, 0, 0.1);
      .form {
        width: 73vw;
        margin: 0 auto;
        .item {
          height: 12vw;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 1px solid rgba(20, 30, 55, 0.3);
          span {
            width: 21vw;
          }
          &:first-child {
            height: 18.133vw;
            display: flex;
            align-items: center;
          }
          input,
          .edit-inp {
            height: 12vw;
            outline: none;
            line-height: 12vw;
            border: none;
            padding-left: 2vw;
          }
          .edit-inp {
            padding-left: 0;
          }
          .eys {
            position: absolute;
            width: 6vw;
            right: 0;
          }
        }
        .avatar-warp {
          width: 13vw;
          height: 13vw;
          position: absolute;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          right: 0;
          margin-top: 1vw;
          input {
            width: 13vw;
            opacity: 0;
            position: absolute;
            top: 3vw;
            left: 0;
          }
        }
        .edit {
          width: 16vw;
          display: block;
          display: block;
          height: 4.667vw;
        }
        .ava-modal {
          position: absolute;
          width: 15vw;
          height: 15vw;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .avatar {
          width: 13vw;
          height: 13vw;
          display: block;
          margin-top: 2vw;
          z-index: 1;
          position: absolute;
          .upbtn {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            margin-top: 0;
            width: 12vw;
          }
        }
      }
    }
  }
  .sure {
    width: 84vw;
    height: 12vw;
    background-color: #3788f7;
    box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
    border-radius: 1vw;
    margin: 4vw auto;
    line-height: 12vw;
    color: #fff;
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
  }
  .tip {
    width: 84vw;
    margin: 0 auto;
    font-size: 3vw;
    color: #b8bbc3;
    line-height: 5vw;
  }
  .footer {
    width: 42vw;
    margin-top: 7vw;
    margin-left: 11vw;
  }
  // .cropper {
  //   width: 80px;
  //   height: 80px;
  //   line-height: 80px;
  //   /* 切记position: relative一点要有 */
  //   position: relative;
  //   border-radius: 80px;
  //   overflow: hidden;
  //   text-align: center;
  // }
}
.edit-user {
  text-align: center;
  width: 73vw;
  height: 12vw;
  background-color: #141e37;
  box-shadow: 0vw 1vw 3vw 0vw rgba(20, 30, 55, 0.56);
  border-radius: 1vw;
  margin: 4vw auto;
  line-height: 12vw;
  display: block !important;
  border: none !important;
  color: #fff;
  font-size: 5vw;
}
</style>
