<template>
  <div class="warp">
    <div class="top">
      <div
        @click="slideTo(1)"
        :class="['top-item', tabIndex == 1 ? 'active' : '']"
      >
        主要<br />功能
      </div>
      <div
        @click="slideTo(2)"
        :class="['top-item', tabIndex == 2 ? 'active' : '']"
      >
        成就<br />打卡
      </div>
      <div
        @click="slideTo(3)"
        :class="['top-item', tabIndex == 3 ? 'active' : '']"
      >
        交个<br />朋友
      </div>
      <div
        @click="slideTo(4)"
        :class="['top-item', tabIndex == 4 ? 'active' : '']"
      >
        个性化<br />焊接
      </div>
      <div
        @click="slideTo(5)"
        :class="['top-item', tabIndex == 5 ? 'active' : '']"
      >
        超级键
      </div>
      <div
        @click="slideTo(6)"
        :class="['top-item', tabIndex == 6 ? 'active' : '']"
      >
        操作<br />说明
      </div>
    </div>
    <img src="../../assets/img/peishi.png"  v-show="tabIndex != 6" class="peishi" alt="" />
    <div class="inner" v-show="tabIndex != 6">
      <div class="inner-item-warp">
        <Swipe :show-indicators="false" :touchable="false" ref="swipe">
          <swipe-item> <img src="./img/1.png" alt="" /></swipe-item>
          <swipe-item> <img src="./img/2.png" alt="" /></swipe-item>
          <swipe-item> <img src="./img/3.png" alt="" /></swipe-item>
          <swipe-item> <img src="./img/4.png" alt="" /></swipe-item>
          <swipe-item> <img src="./img/5.png" alt="" /></swipe-item>
        </Swipe>
      </div>
    </div>
    <div class="explain" v-show="tabIndex == 6">
      <div class="title">WaveBadge说明</div>
      <div class="content">
        <img src="./img/explain.png" alt="">
      </div>
    </div>
    <MapTab />
  </div>
</template>

<script>
import MapTab from '../../components/MapTab'
import { Swipe, SwipeItem } from 'vant'
export default {
  components: {
    MapTab,
    Swipe,
    SwipeItem
  },
  data () {
    return {
      tabIndex: 1
    }
  },
  methods: {
    slideTo (index) {
      this.tabIndex = index
      this.$refs.swipe.swipeTo(index - 1)
    }
  }
}
</script>

<style lang='less' scoped>
.warp {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  .top {
    height: 13vw;
    display: flex;
    align-items: center;
    background: #e0e3ec;
    .top-item {
      width: 20%;
      text-align: center;
      font-size: 3vw;
      color: #728dac;
      &.active {
        color: #3788f7;
      }
    }
  }
  .peishi {
    width: 24vw;
    margin: 12vw 0;
  }
  .inner {
    width: 93vw;
    height: 98vw;
    background-color: #fff;
    border-radius: 3vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
    .inner-item-warp {
      width: 85vw;
      img {
        width: 100%;
      }
    }
  }
}
.explain {
  width: 90vw;
  margin: 5vw auto;
  height: 80vh;
  overflow: auto;
  .title {
    height: 18vw;
    line-height: 18vw;
    text-align: center;
    background: #3788f7;
    border-top-left-radius: 4vw;
    border-top-right-radius: 4vw;
    font-size: 4vw;
    color: #fff;
    font-weight: bold;
    position: relative;
  }
  .title::before {
    position: absolute;
    content: "";
    width: 4vw;
    height: 6vw;
    background: url(./img/left.png) no-repeat center;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    left: 20vw;
  }
  .title::after {
    position: absolute;
    content: "";
    width: 4vw;
    height: 6vw;
    background: url(./img/right.png) no-repeat center;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    right: 20vw;
  }
  .content{
    box-sizing: border-box;
    padding: 7vw 0;
    background: #fff;
    img{

      width: 74vw;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
