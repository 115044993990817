<template>
  <div class="warp">

  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  created () {
    this.$cookies.remove('tel')
    this.$cookies.remove('cardNum')
    Dialog.alert({
      title: '标题',
      message: '信息清除成功,点击确定返回登录',
      theme: 'round-button'
    }).then(() => {
      // on close
      this.$router.push('/')
    })
  }
}
</script>

<style>

</style>
