<template>
  <div :class="['map-tab-war', show ? 'active' : '']">
    <div class="map-tab">
      <router-link to="/map" tag="div" class="item">
        <img src="./img/location.png" alt="" />
        <span class="menuactive">打卡地图</span>
      </router-link>
      <router-link to="/user" tag="div"  class="item">
        <img src="./img/my-card.png" alt="" />
        <span class="menuactive">我的名片</span>
      </router-link >
      <router-link class="item" tag="div" to="/friends">
        <img src="./img/friends-l.png" alt="" />
        <span class="menuactive"
          >好友名片</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    showIndex: String
  }
}
</script>

<style lang='less' scoped>
.map-tab-warp {
  height: 48vw;
  width: 100vw;
  bottom: 0;
  left: 0;
  position: fixed;
  background-image: url(./img/footer.png);
  background-repeat: no-repeat;
  background-position: 0px 4vw;
  background-size: 100vw 30.133vw;
  background-color: #0087ff;
  &.active {
    height: 48vw;
    background: none;
  }
}
.map-tab {
  width: 100vw;
  height: 19vw;
  position: absolute;
  bottom: 0;
  background-color: #0087ff;
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    width: 33.3%;
    height: 13vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    img {
      width: 3vw;
    }
    &:nth-child(2) {
      img {
        width: 6vw;
      }
    }
    &:nth-child(3) {
      img {
        width: 5vw;
      }
    }
    span {
      font-size: 3vw;
      color: #728dac;
      margin-top: 2vw;
      height: 6vw;
    }
  }
}
.menuactive {
  color: #fff !important;
}
</style>
