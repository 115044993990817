<template>
  <div class="warp">
    <h2>请您签到时将专属二维码<br />出示于现场工作人员</h2>
    <div class="qr-warp">
      <div class="qr-inner">
          <img :src="imgUrl" alt="">

      </div>
      <p>专属二维码请勿转发他人</p>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '../../api'
export default {
  data () {
    return {
      imgUrl: 'http://www.event.com.cn/qrcode/?m=1&text='
    }
  },
  async created () {
    const tel = this.$cookies.get('tel')
    const data = await getUserInfo({ phone: tel })
    this.imgUrl = `http://www.event.com.cn/qrcode/?m=1&text=${data.data.userid}`
  }
}
</script>

<style lang='less' scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background: url(./img/bg.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 43vw;
  h2 {
    width: 57vw;
    height: 13vw;
    font-size: 5vw;
    font-stretch: normal;
    line-height: 7vw;
    letter-spacing: 0vw;
    color: #844ade;
    text-align: center;
    margin: 0 auto;
  }
  .qr-warp {
    width: 57vw;
    height: 68vw;
    background-color: #f9fafc;
    border-radius: 3vw;
    margin: 5vw auto;
    padding: 3vw;
    box-sizing: border-box;
    .qr-inner {
      width: 51vw;
      height: 51vw;
      border-radius: 4vw;
    //   margin: 3vw;
      background: linear-gradient(#2f4dc8, transparent),
          linear-gradient(to top left, #8459e8, transparent),
          linear-gradient(to top right, #8559e8, transparent);
        background-blend-mode: screen;

    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 45vw;
    height: 45vw;
    }
    }
    p{
        text-align: center;
        color: #141e37;
        font-size: 3vw;
        margin-top: 4vw;
    }
  }
}
</style>
