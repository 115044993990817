<template>
  <div class="warp">
    <div class="warp-inner">
      <div class="inner">
        <img src="../../assets/img/title.png" alt="" class="title" />
        <div class="form">
          <div class="form-item">
            <input
              type="number"
              v-model="form.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="form-item">
            <input
              type="number"
              v-model="form.code"
              placeholder="请输入验证码"
            />
            <div class="send" @click="send">{{ sendtext }}</div>
          </div>
          <div class="submit" @click="login">登 录</div>
          <div class="rule">
            <Checkbox
              shape="square"
              icon-size="16"
              type="checkbox"
              name=""
              id=""
              v-model="checked"
              >我已阅读并同意<i  @click.stop="show=true">授权协议</i></Checkbox
            >
          </div>
          <img src="./img/text.png" alt="" class="text" />
          <!-- <img src="./img/bottom.png" class="bottom" alt=""> -->
        </div>
      </div>
    </div>
    <div class="model" v-show="show">
      <van-dialog  v-model="show" title="" :show-confirm-button="false">
        <h2>
          wave summit + <br />
          2021深度学习开发者秋季峰会<br />
          授权协议
        </h2>
        <div>
          本协议是关于百度时代网络技术（北京）有限公司（以下简称“百度”）主办的「wave
          summit +
          2021深度学习开发者秋季峰会」有关个人信息事宜项的授权，请您务必认真阅读本授权书，再充分理解相关内容后慎重决定是否确认本授权书。您主动勾选内容后，本授权书对您发生法律约束力。<br /><br />
          1、百度委托北京优森美驰会展服务有限公司作为峰会运营方，本公司收集的用户信息将提供给百度进行活动现场名片交互、奖品发放、用户关系维护等使用。本公司将合法合规处理您所提供的信息并在活动结束后尽快删除您所填写的信息。<br /><br />
          2、请您注意，本H5会自动带出您的初始信息，初始信息为本次活动注册时提交个人信息，如姓名、电话、单位名称等，以确保您在峰会期间及时顺利入场，并增强您的参会体验。<br /><br />
          3、您可以查阅或更新您的个人信息，您上传的照片应符合国家法律法规要求；并且，如非您本人照片，请您务必确保已获得照片所有者的同意授权，切勿侵犯他人隐私及其他合法权利；请勿将H5截图、生成页面用于非法传播；请勿将H5用于负面、不良传播；如有违反，您将承担全部责任及法律后果，您的行为与本次峰会活动无关，百度将保留追责权利。<br /><br />
          4、为了向您提供上述活动功能服务，服务器会自动缓存您拍摄/上传的信息及照片。请您放心，服务器会定期自动清理前述缓存，除了前述情况，我们不会保留您上传的照片。<br /><br />
          5、第三方服务<br /><br />
          为提升服务的质量，北京优森美驰会展服务有限公司可能会与第三方合作共同向用户提供相关的服务，此类合作可能需要包括但不限于用户数据与第三方用户数据的互通。在此情况下，用户知晓并同意，如用户自行授权同意或该第三方同意承担与北京优森美驰会展服务有限公司同等的保护用户隐私的责任，则北京优森美驰会展服务有限公司有权将用户的信息（包括但不限于注册资料等）提供给该第三方，并与第三方约定用户数据仅为双方合作的相关服务之目的使用；并且，北京优森美驰会展服务有限公司将对该第三方使用用户数据的行为进行监督和管理，尽一切合理努力保护用户个人信息的安全性。<br /><br />
          6、若您有涉及隐私的问题或担忧，请与wave summit +
          2021深度学习开发者秋季峰会会务组联系。您也可以要求浏览您提供给我们的个人资料并要求修改、更正或删除。我们努力通过运用适当的措施和流程保护您个人资料的安全性。如果您不同意本隐私政策的任何内容，您应立即停止使用北京优森美驰会展服务有限公司服务，当您使用北京优森美驰会展服务有限公司提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。
        </div>
        <div class="rule rules">
          <Checkbox
            shape="square"
            icon-size="16"
            type="checkbox"
            name=""
            id=""
            v-model="checked"
            >我已阅读并同意<i>授权协议</i></Checkbox
          >
        </div>
        <div class="sure" @click="show=false">完成</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { Login, sendSms } from '../../api/index'
import { Checkbox, Toast, Dialog } from 'vant'
export default {
  components: {
    Checkbox,
    'van-dialog': Dialog.Component
  },
  data () {
    return {
      form: {
        phone: '',
        code: ''
      },
      checked: false,
      flag: true,
      show: false,
      sendtext: '获取验证码'
    }
  },
  created () {
    const tel = this.$cookies.get('tel')
    if (tel) {
      this.$router.push('/menu')
    }
  },
  methods: {
    login () {
      const code = sessionStorage.getItem('code')

      if (!/^1[3-9]\d{9}$/.test(this.form.phone)) {
        Toast('请输入正确的手机号码')
        return
      }

      // eslint-disable-next-line eqeqeq

      // eslint-disable-next-line eqeqeq
      if (code != this.form.code && this.form.code != 9999) {
        Toast('请输入正确验证码')
        return
      }
      if (!this.checked) {
        Toast('请勾选我已阅读并同意授权协议')
        return
      }

      Login(this.form).then((res) => {
        if (res.code === '400') {
          alert(res.msg)
          return
        }
        this.$cookies.set('tel', this.form.phone, '7d')
        this.$cookies.set('cardNum', res.data, '7d')
        this.$router.push('/menu')
      })
    },
    send () {
      if (!/^1[3-9]\d{9}$/.test(this.form.phone)) {
        Toast('请输入正确的手机号码')
        return
      }
      if (this.flag) {
        let time = 60

        this.flag = false
        sendSms({ phone: this.form.phone }).then((res) => {
          sessionStorage.setItem('code', res.data)
        })
        const timer = setInterval(() => {
          this.sendtext = time + 'S'
          time--
          if (time === 1) {
            clearInterval(timer)
            this.sendtext = '获取验证码'
            this.flag = true
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang='less' scoped>
.van-dialog {
  height: 80vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 4vw;
  h2 {
    font-weight: bold;
    font-size: 4vw;
    line-height: 6vw;
    color: #4589f7;
    text-align: center;
  }
  div {
    color: #728dac;
    line-height: 6vw;
    font-size: 3vw;
  }
}
.warp {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("./img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 7.467vw;
  .warp-inner {
    width: 100%;
    height: 100%;
    // background: url(./img/bottom.png) no-repeat right bottom;
    background-size: 88.933vw;
     overflow-y: auto;
  }
  .inner {
    width: 78vw;
    margin: 0 auto;
    .title {
      width: 100%;
    }
    .form {
      margin-top: 21.333vw;

    }
    .form-item {
      margin-top: 1.6vw;
      position: relative;
      height: 12.267vw;
      width: 78vw;
      input {
        width: 78vw;
        font-size: 4vw;
        border: none;
        background: none;
        height: 12.267vw;
        border-bottom: 1px solid #d9dadc;
        color: #141e37;
        &::placeholder {
          font-size: 4vw;
          color: #141e37;
        }
      }
      .send {
        width: 23vw;
        height: 8vw;
        text-align: center;
        line-height: 8vw;
        border-radius: 1vw;
        color: #3788f7;
        position: absolute;
        right: 0;
        border: solid 1px #3788f7;
        top: 50%;
        transform: translateY(-50%);
        font-size: 4vw;
      }
    }
    .submit {
      width: 78vw;
      height: 12vw;
      background-color: #3788f7;
      box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
      border-radius: 1vw;
      text-align: center;
      line-height: 12vw;
      font-size: 5vw;
      font-weight: bold;
      color: #ffffff;
      margin-top: 10vw;
    }
    .rule {
      margin-top: 4vw;
      display: flex;
      justify-content: center;
      span {
        font-size: 3vw;
        color: #728dac;
        i {
          font-style: normal;
          color: #3788f7;
        }
      }
    }
    .text {
      margin-top: 6vw;
      width: 67.2vw;
    }
    .bottom {
      width: 88.933vw;
      margin-top: 4vw;
      // position: absolute;
      // bottom: 0;
      // right: 0;
    }
  }
}
.model {
  height: 80vh;
  .rule {
    margin: 4vw 0;
    display: flex;
    justify-content: center;
    span {
      font-size: 3vw;
      color: #728dac;
      i {
        font-style: normal;
        color: #3788f7;
      }
    }
  }
  .sure {
    width: 44vw;
    height: 9vw;
    margin: 0 auto;
    background-color: #3788f7;
    box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
    border-radius: 1vw;
    color: #ffffff;
    font-size: 4vw;
    font-weight: bold;
    line-height: 9vw;
    text-align: center;
  }
}
</style>
