<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { Login } from './api'
export default {
  created () {
    const tel = this.$cookies.get('tel')
    if (tel) {
      // this.$router.push('/menu')

      Login({ phone: tel, code: 9999 }).then((res) => {
        if (res.code === '400') {
          alert(res.msg)
          return
        }

        this.$cookies.set('cardNum', res.data, '7d')
      })
    }
  }
}
</script>

<style lang="less" >
* {
  margin: 0;
  padding: 0;
  font-family: "Source Han Sans CN";
}
</style>
