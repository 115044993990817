import request from '../utils/request'
import qs from 'qs'

export function Login (data) {
  return request({
    url: '/get_Login_action',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function sendSms (data) {
  return request({
    url: '/set_Send_sms',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function getUserInfo (data) {
  return request({
    url: '/get_Userinfo_data',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function getMapPos (data) {
  return request({
    url: '/get_User_friend',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function uploadAvatar (data) {
  return request({
    url: '/set_Pic_upload',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function updateUsers (data) {
  return request({
    url: '/set_User_update',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function queryFriend (data) {
  return request({
    url: '/get_Friends_userinfo',
    data: qs.stringify(data),
    method: 'POST'
  })
}

export function detectionWord (data) {
  return request({
    url: '/check_word',
    data: qs.stringify(data),
    method: 'POST'
  })
}
