<template>
  <div class="warp">
    <div class="warp-inner">
      <div class="inner">
        <img src="./img/title.png" alt="" class="title" />
        <img src="./img/top.png" alt="" class="top" />
        <router-link to="/check-in" tag="div" class="item qrcode"
          ><span>参会二维码</span></router-link
        >
        <router-link to="/my-card" class="item my" tag="div"
          ><span>我的卡</span></router-link
        >
        <router-link to="/agenda" tag="div" class="item agenda"
          ><span>峰会议程</span></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang='less' scoped>
.warp {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 7.467vw;
  .warp-inner {
    width: 100%;
    height: 100%;
    background: url(./img/bottom.png) no-repeat right bottom;
    background-size: 88.933vw;
  }
  .inner {
    width: 78vw;
    margin: 0 auto;
    .title {
      width: 48.933vw;
      margin-bottom: 16vw;
    }
    .top {
      position: fixed;
      top: 0;
      right: 0;
      width: 15.067vw;
    }
    .item {
      width: 78vw;
      height: 12vw;
      background-color: #f9fafc;
      border-radius: 1vw;
      text-align: center;
      line-height: 12vw;
      box-shadow: 0vw 1vw 3vw 0vw rgba(0, 0, 0, 0.2);
      font-size: 5vw;
      font-weight: bold;
      font-size: 5vw;
      margin-top: 10vw;
      color: #f9fafc;
      position: relative;
      span {
        position: absolute;
        z-index: 2;
        width: 100%;
        text-align: center;
        left: 0;
      }
      &::after,
      &::before {
        content: "";
        // border: 1vw solid #fff;
        display: block;
        width: 78vw;
        //  box-shadow: 0vw 1vw 3vw 0vw rgba(0, 0, 0, 0.26);
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        height: 12vw;
        position: absolute;
        opacity: 1;
        border-radius: 2vw;
        transform-origin: center;
      }
      &::before {
        animation: Wave 2.7s ease-in-out .2s infinite;
      }
      &::after {
        animation: Wave 2.7s ease-in-out 2s infinite;
      }
    }
    .qrcode {
      background: #844ade;
      box-shadow: 0vw 1vw 3vw 0vw rgba(132, 74, 222, 0.56);
      &::after,
      &::before {
         background: transparent;
        border: 2px solid rgba(132, 74, 222, 0.2);
      }
    }
    .my {
      background-color: #3788f7;
      box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
      &::after,
      &::before {
        background: transparent;
        border: 2px solid rgba(55, 136, 247, 0.2);
      }
    }
    .agenda {
      background-color: rgb(233, 222, 4);
      box-shadow: 0vw 1vw 3vw 0vw rgba(233, 222, 4, 0.26);
      &::after,
      &::before {
        content: "";
        // border: 1vw solid #fff;
        width: 78vw;
        background: transparent;
        border: 2px solid rgba(233, 222, 4, 0.2);
      }
    }
  }
}

@keyframes Wave {
  0% {
    transform: scale(.8  );
    opacity: .4;
    height: 12vw;
    // background: rgba(255, 255, 255, 0);
  }

  50% {
    transform: scale(1.03);
    height: 14vw;
    // background: rgba(255, 255, 255, 0.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    height: 12vw;
    // background: rgba(255, 255, 255, 0.2);
  }
}
</style>
