<template>
  <div class="map-warp">
    <div class="text">
      请您按照地图所示，完成以下打卡任务，点亮胸卡所有成就灯，即可获得峰会精美礼品。数量有限，先到先得！
    </div>
    <div class="map-inner">
      <div class="warp">
        <div class="map-top">
          <div class="left">
            <div class="top">
              <div :class="['top-left', pos[6] == 1 ? 'active' : '']">
                分<br />论<br />坛
                <img src="./img/quan.png" v-if="pos[6] == 0" alt="" />
                <img src="./img/quan1.png" v-else alt="" />
              </div>
              <div class="top-right">
                <div :class="['HDZQ', pos[7] == 1 ? 'active' : '']">
                  互动展区
                  <img src="./img/quan.png" v-if="pos[7] == 0" alt="" />
                  <img src="./img/quan1.png" v-else alt="" />
                </div>
                <div>
                  <div :class="['top-bottom', pos[8] == 1 ? 'active' : '']">
                    <div class="js-left"></div>
                    <div :class="['js-right', pos[8] == 1 ? 'active' : '']">
                      开发者市集
                      <img src="./img/quan.png" v-if="pos[8] == 0" alt="" />
                      <img src="./img/quan1.png" v-else alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="xsj-warp">
              <div :class="[pos[6] == 1 ? 'active' : '']">
                <img src="./img/xsj.png" alt="" />
              </div>
              <div></div>
            </div>
          </div>
          <div class="right">
            <div :class="['zlt', pos[5] == 1 ? 'active' : '']">
              主会场
              <img src="./img/quan.png" v-if="pos[5] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
            </div>
            <div :class="['pinpai', fivestatus == 1 ? 'active' : '']">
              品牌展区
              <img src="./img/quan.png" v-if="pos[0] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
              <img src="./img/quan.png" v-if="pos[1] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
              <img src="./img/quan.png" v-if="pos[2] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
              <img src="./img/quan.png" v-if="pos[3] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
              <img src="./img/quan.png" v-if="pos[4] == 0" alt="" />
              <img src="./img/quan1.png" v-else alt="" />
            </div>
            <div :class="['zw', fivestatus == 1 ? 'active' : '']"></div>
            <div class="xsj"><img src="./img/xsj.png" alt="" /></div>
          </div>
        </div>

        <div class="map-bottom">
          <div class="xxq">PPDE&AICA<br />休息区</div>
          <div class="zws"></div>
          <div :class="['dhq', pos[9] == 1 ? 'active' : '']">
            礼品<br />兑换
          </div>
        </div>
      </div>
      <img class="explain" src="./img/explain.png" alt="" />
    </div>
    <div class="poster" @click="handlePoster">一键分享</div>
    <img src="./img/footer.png" class="footer" alt="" />
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div>
          <img v-if="total==1" src="./img/hb01.jpg" alt="" />
          <img v-else-if="total==2" src="./img/hb02.jpg" alt="" />
          <img v-else-if="total==3" src="./img/hb03.jpg" alt="" />
          <img v-else-if="total==4" src="./img/hb04.jpg" alt="" />
          <img v-else-if="total==5" src="./img/hb05.jpg" alt="" />
          <img v-else-if="total==6" src="./img/hb06.jpg" alt="" />
        </div>
        <van-icon name="close" size="28" color="#ffffff" @click="show = false" />
        <p>长按上方图片保存，分享朋友圈</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getMapPos } from '../../api'
import { Overlay, Icon, Toast } from 'vant'
let timer

export default {
  components: {
    'van-overlay': Overlay,
    'van-icon': Icon
  },
  data () {
    return {
      pos: [],
      total: 0,
      show: false,
      fivestatus: 0
    }
  },
  async created () {
    this.getData()
    timer = setInterval(() => { this.getData() }, 5000)
  },
  methods: {
    handlePoster () {
      // eslint-disable-next-line eqeqeq
      if (this.total == 0) {
        Toast('请您移步至指定点位打卡后，生成海报并分享。')
        return
      }
      this.show = true
    },
    async getData () {
      const data = await getMapPos({
        cardnum: this.$cookies.get('cardNum')
      })
      this.pos = data.data[0].flags
      this.total = data.data[0].total
      this.fivestatus = data.data[0].fivestatus
    }
  },
  destroyed () {
    clearInterval(timer)
  }
}
</script>

<style lang='less' scoped>
.footer {
  width: 42vw;
  display: block;
  margin-left: 8vw;
  margin-top: 7vw;
}
.map-warp {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/bg.jpg") no-repeat top center;
  background-size: cover;
  box-sizing: border-box;
  padding-top: 4vw;
  .text {
    width: 82vw;
    margin: 0 auto;
    color: #728dac;
    font-size: 3vw;
  }
  .map-inner {
    width: 84vw;
    height: 130vw;
    margin: 4vw auto;
    background: #ffffff;
  }
}
.warp {
  width: 74vw;
  height: 104vw;
  background-color: #14162b;
  border-radius: 3vw;
  display: flex;
  margin: 4vw auto;
  padding: 5vw;
  box-sizing: border-box;
  color: #48537b;
  font-size: 3vw;
  flex-direction: column;
  .map-top {
    display: flex;
    height: 70.6vw;
  }
  .top-right {
    margin-left: 1vw;
  }
  .left {
    width: 40vw;
    height: 42vw;
    .top {
      width: 40vw;
      //height: 42vw;
      display: flex;
    }
  }
  .top-left {
    width: 14vw;
    height: 42vw;
    background: #1b1e35;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.active {
      background: #52ab5a;
      color: #ffffff;
      text-shadow: 0.2rem 0rem 0.5rem #ffffff, -0.2rem 0rem 0.5rem #ffffff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
      //background: linear-gradient(0deg,rgba(82, 171, 90,.1),#1b1e35 30% );
    }
    img {
      width: 5vw;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -2vw;
    }
  }
  .HDZQ {
    background: #1b1e35;
    width: 25vw;
    height: 22vw;
    position: relative;
    text-align: center;
    line-height: 11vw;
    position: relative;
    &.active {
      background: #e2bd51;
      text-shadow: 0.2rem 0rem 0.5rem #ffffff, -0.2rem 0rem 0.5rem #ffffff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
      &::after {
        width: 19vw;
        height: 11vw;
        content: "";
        background: url("./img/ql_01.jpg") no-repeat center;
        background-size: cover;
        display: block;
        position: absolute;
        right: 0vw;
        bottom: 0;
      }
    }
    img {
      width: 5vw;
      position: absolute;
      left: 4vw;
      top: 50%;
      margin-top: -2vw;
      z-index: 999;
    }
    &::after {
      width: 19vw;
      height: 11vw;
      content: "";
      background: url("./img/q01.jpg") no-repeat center;
      background-size: cover;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .top-bottom {
    display: flex;
    width: 25vw;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1vw;
    position: relative;
    &.active {
      &:after {
        width: 19vw;
        height: 11vw;
        content: "";
        background: url("./img/ql_02.jpg") no-repeat center;
        background-size: cover;
        display: block;
        position: absolute;
        right: 0;
        bottom: 9vw;
      }
      .js-left {
        background: linear-gradient(rgba(226, 189, 81, 0.2), transparent),
          linear-gradient(to top left, rgba(99, 60, 220, 0.1), transparent),
          linear-gradient(to top right, rgba(99, 60, 220, 0.2), transparent);
        background-blend-mode: screen;
      }
    }
    &:after {
      width: 19vw;
      height: 11vw;
      content: "";
      background: url("./img/q02.jpg") no-repeat center;
      background-size: cover;
      display: block;
      position: absolute;
      right: 0;
      bottom: 9vw;
    }
    .js-left {
      height: 19vw;
      width: 6vw;
      background: #1b1e35;
    }
    .js-right {
      width: 18vw;
      height: 11vw;
      background: #1b1e35;
      text-align: center;
      line-height: 12vw;
      position: relative;
      &.active {
        color: #fff;
        background: #633cdc;
      }
      img {
        width: 5vw;
        position: absolute;
        left: 50%;
        bottom: -1vw;
        transform: translateX(-50%);
        z-index: 999;
      }
    }
  }
  .xsj-warp {
    margin-top: 1vw;
    width: 40vw;
    height: 28vw;
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        width: 21vw;
        height: 28vw;
        background: #1b1e35;
        position: relative;
        &.active {
          background: linear-gradient(rgba(46, 77, 64, 0.6) 0%, #1b1e35 40%);
        }
        img {
          width: 4.9vw;
          position: absolute;
          bottom: 2vw;
          left: 2vw;
        }
      }
      &:last-child {
        width: 18vw;
        height: 28vw;
        background: #1b1e35;
      }
    }
  }
}
.right {
  width: 24vw;
  margin-left: 1vw;
  .zlt {
    background: #1b1e35;
    height: 38vw;
    line-height: 38vw;
    text-align: center;
    position: relative;
    &.active {
      background: #3889ee;
      color: #ffffff;
      text-shadow: 0.2rem 0rem 0.5rem #ffffff, -0.2rem 0rem 0.5rem #ffffff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
    }
    img {
      width: 5vw;
      position: absolute;
      right: 2vw;
      bottom: 2vw;
      margin-top: -2vw;
      z-index: 999;
    }
  }
  .pinpai {
    margin-top: 1vw;
    width: 24vw;
    height: 9vw;
    background: #1b1e35;
    line-height: 9vw;
    text-align: center;
    position: relative;
    &.active {
      color: #ffffff;
      background: #d048b1;
      text-shadow: 0.2rem 0rem 0.5rem #ffffff, -0.2rem 0rem 0.5rem #ffffff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
    }
    img {
      width: 5vw;
      position: absolute;
      z-index: 999;
      &:nth-child(1) {
        left: 4.6vw;
        top: -2vw;
      }
      &:nth-child(2) {
        left: 9.6vw;
        top: -2vw;
      }
      &:nth-child(3) {
        left: 14.6vw;
        top: -2vw;
      }

      &:nth-child(4) {
        left: 6.6vw;
        bottom: -2vw;
      }
      &:nth-child(5) {
        right: 0;
        top: 50%;
        margin-top: -2vw;
      }
    }
  }

  .zw {
    margin-top: 1vw;
    height: 11vw;
    background: #1b1e35;
    text-align: center;
    line-height: 11vw;
    &.active {
      // background: linear-gradient(40deg,rgba(209, 72, 179,.1) 30%,#1b1e35 30% );
      background: linear-gradient(rgba(102, 45, 102, 0.6) 0%, #1b1e35 40%);
    }
  }
  .xsj {
    margin-top: 1vw;
    height: 10vw;
    background: #1b1e35;
    text-align: center;
    line-height: 10vw;
    position: relative;
    img {
      width: 4.9vw;
      position: absolute;
      bottom: 0;
      right: 3vw;
    }
  }
}
.map-bottom {
  width: 74vw;
  height: 16vw;
  display: flex;
  margin-top: 1.6vw;
  .xxq,
  .dhq {
    width: 10vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1b1e35;
  }
  .xxq{
    font-size: 2vw;
  }
  .zws {
    width: 43vw;
    background: #1b1e35;
    margin-left: 1vw;
  }
  .dhq {
    margin-left: 1vw;
    &.active {
      background: #134af5;
      color: #ffffff;
      text-shadow: 0.2rem 0rem 0.5rem #ffffff, -0.2rem 0rem 0.5rem #ffffff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
    }
  }
}
.explain {
  width: 70vw;
  display: block;
  margin: 3vw auto 0;
}
// .wrapper
.van-overlay{
  z-index: 999;
}
.poster {
  width: 84vw;
  height: 12vw;
  background-color: #3788f7;
  box-shadow: 0vw 1vw 3vw 0vw rgba(55, 136, 247, 0.56);
  border-radius: 1vw;
  margin: 3vw auto;
  text-align: center;
  line-height: 12vw;
  font-size: 5vw;
  font-weight: bold;
  color: #ffffff;
  line-height: 12vw;
  text-align: center;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    width: 66%;
    margin: 0 auto;
    margin-top: -8vw;
    position: relative;

  }
  div {
    position: relative;
    margin-bottom: 4.5vw;
    &::after {
      width: 1px;
      height: 5vw;
      content: "";
      position: absolute;
      background: #fff;
      left: 50%;
      transform: translateX(-50%);
      display: block;
    }
  }
  p {
    color: #3788f7;
    margin-top: 2vw;
    font-size: 4vw;
    // font-weight: bold;
  }
}
</style>
